<script>
import { mapState, mapActions } from 'vuex'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueGoodTable } from 'vue-good-table'
import { required } from '@validations'
import PaginationCustom from '@/components/table/Pagination.vue'

export default {
   name: 'Pengaduan',
   components: {
      BCardCode,
      VueGoodTable,
      PaginationCustom,
   },
   data() {
      return {
         item: {},
         mode: 'INSERT',
         form: {
            nomor: null,
            nama: null,
            nohp: null,
            hal: null,
            keterangan: null,
            status: null,
            tgl_pengaduan: null,
            tgl_selesai: null,
         },
         required,
         serverParams: {
            columnFilters: {},
            sort: {
               field: '',
               type: '',
            },
            search: '',
            page: 1,
            perPage: 10,
         },
         pageLength: 10,
         pageOptions: ['10', '25', '50', '100'],
         searchTerm: '',
         columns: [
            {
               label: 'Nomor',
               field: 'nomor',
               sortable: false,
               thClass: 'text-center align-middle',
            }, {
               label: 'Tanggal Pengaduan',
               field: 'tanggal',
               sortable: false,
               thClass: 'text-center align-middle',
            },

            {
               label: 'Perihal',
               field: 'hal',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Nama Pelapor',
               field: 'nama',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Status',
               field: 'status',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Action',
               field: 'action',
               sortable: false,
               thClass: 'text-center align-middle',
            },
         ],
         optionsStatus: [
            { value: null, text: 'Pilih Status' },
            { value: 'proses', text: 'Dalam Proses' },
            { value: 'selesai', text: 'Selesai' },
         ],
      }
   },
   computed: {
      ...mapState({
         myData: state => state.Pengaduan.items,
      }),
      isLoading() {
         return this.$store.state.isLoading
      },
      statusVariant() {
         const data = {
            /* eslint-disable key-spacing */
            selesai: 'success',
            proses: 'danger',
            /* eslint-enable key-spacing */
         }
         return status => (data[status] ? data[status] : 'primary')
      },
      statusPengaduan() {
         const data = {
            /* eslint-disable key-spacing */
            selesai: 'Selesai',
            proses: 'Proses',
            /* eslint-enable key-spacing */
         }
         return status => (data[status] ? data[status] : '-')
      },
   },
   mounted() {
      this.loadItems()
   },
   methods: {
      ...mapActions({
         getData: 'Pengaduan/getData',
         getItem: 'Pengaduan/getItem',
         postItem: 'Pengaduan/postItem',
         updateItem: 'Pengaduan/updateItem',
         deleteItem: 'Pengaduan/deleteItem',
      }),
      onAdd() {
         this.resetForm()
         this.$bvModal.show('modal-form')
      },
      onEdit(kode) {
         this.getItem(kode).then(item => {
            this.form = item
            this.mode = 'UPDATE'
            this.$bvModal.show('modal-form')
         })
      },
      onDelete(kode) {
         this.$swal({
            title: 'Apakah anda yakin?',
            text: 'Data tidak dapat dipulihkan kembali!',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: 'Ya, Hapus!',
            html: false,
            allowOutsideClick: false,
            customClass: {
               confirmButton: 'btn btn-danger',
               cancelButton: 'btn btn-outline-dark ml-1',
            },
            buttonsStyling: false,
            preConfirm: () => this.deleteItem(kode).then(() => {
               this.loadItems()
               this.$swal('Deleted!', '', 'success')
            }).catch(() => {
               this.$swal('Gagal!', '', 'error')
            }),
         })
      },
      onSave() {
         this.$refs.myForm.validate().then(success => {
            console.log(success)
            if (success) {
               if (this.mode === 'INSERT') {
                  this.postItem(this.form).then(() => {
                     this.loadItems()
                     this.$bvModal.hide('modal-form')
                  })
               } else if (this.mode === 'UPDATE') {
                  this.updateItem(this.form).then(() => {
                     this.loadItems()
                     this.$bvModal.hide('modal-form')
                  })
               }
            }
         })
      },
      resetForm() {
         this.form = {
            nomor: null,
            nama: null,
            nohp: null,
            hal: null,
            keterangan: null,
            status: null,
            tgl_pengaduan: null,
            tgl_selesai: null,
         }
         this.mode = 'INSERT'
      },
      onDetail(id) {
         this.item = this.myData.find(item => item.id === id)
         this.$bvModal.show('modal-detail')
      },
      // VUE GOOD TABLE
      loadItems() {
         this.loading = true
         this.getData(this.serverParams)
      },
   },
}
</script>

<template>
   <div>
      <b-card-code title="Data Pengaduan">
         <!-- search input -->
         <b-row>
            <!-- TOOLBAR -->
            <b-col cols="12" md="6">
               <b-form @submit.stop.prevent>
                  <div class="d-flex align-items-center justify-content-end">
                     <b-form-input
                        v-model="searchTerm"
                        class="d-inline-block mb-1"
                        placeholder="Search..."
                        autocomplete="off"
                        type="text"
                        aria-label="Search"
                     />
                  </div>
               </b-form>
            </b-col>
            <b-col cols="12" md="6">
               <div class="d-flex justify-content-end">
                  <b-button-group class="mb-1">
                     <b-button
                        v-b-tooltip.hover.top="'Tambah Pengaduan'"
                        variant="outline-secondary"
                        size="sm"
                        @click="onAdd()"
                     >
                        <feather-icon icon="PlusIcon" />
                     </b-button>
                     <b-button
                        v-b-tooltip.hover.top="'Refresh Data'"
                        variant="outline-secondary"
                        size="sm"
                        @click="loadItems()"
                     >
                        <b-spinner v-if="isLoading" small />
                        <feather-icon v-if="!isLoading" icon="RefreshCwIcon" />
                     </b-button>
                     <b-dropdown variant="outline-secondary" right text="Menu" size="sm">
                        <b-dropdown-item @click="onAdd()">
                           Tambah
                        </b-dropdown-item>
                        <b-dropdown-item @click="loadItems()">
                           Refresh
                        </b-dropdown-item>
                     </b-dropdown>
                  </b-button-group>
               </div>
            </b-col>
         </b-row>

         <!-- table -->
         <vue-good-table
            ref="goodTable"
            :columns="columns"
            :rows="myData"
            :line-numbers="true"
            :pagination-options="{
               enabled: true,
               mode: 'pages',
            }"
            :search-options="{
               enabled: true,
               externalQuery: searchTerm,
            }"
         >
            <template slot="table-row" slot-scope="props">
               <span v-if="props.column.field == 'nomor'">{{ props.row.nomor }}</span>
               <span v-if="props.column.field == 'hal'">{{ props.row.hal }}</span>
               <span v-if="props.column.field == 'nama'">{{ props.row.nama }}</span>
               <span
                  v-if="props.column.field == 'tanggal'"
               >{{ props.row.tgl_pengaduan | moment("DD MMMM YYYY") }}</span>
               <span v-if="props.column.field == 'status'">
                  <center>
                     <b-badge :variant="statusVariant(props.row.status)" class="text-white">
                        <small>{{ statusPengaduan(props.row.status) }}</small>
                     </b-badge>
                  </center>
               </span>

               <span v-if="props.column.field == 'action'">
                  <center>
                     <b-button-group>
                        <b-button
                           v-b-tooltip.hover.top="'Detail'"
                           variant="outline-dark"
                           size="sm"
                           @click="onDetail(props.row.id)"
                        >
                           <feather-icon icon="EyeIcon" />
                        </b-button>
                        <b-button
                           v-b-tooltip.hover.top="'Edit'"
                           variant="outline-dark"
                           size="sm"
                           @click="onEdit(props.row.id)"
                        >
                           <feather-icon icon="EditIcon" />
                        </b-button>
                        <b-button
                           v-b-tooltip.hover.top="'Delete'"
                           variant="outline-danger"
                           size="sm"
                           @click="onDelete(props.row.id)"
                        >
                           <feather-icon icon="TrashIcon" />
                        </b-button>
                     </b-button-group>
                  </center>
               </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
               <pagination-custom
                  :total="props.total"
                  :page-length="pageLength"
                  :page-options="pageOptions"
                  :page-changed="props.pageChanged"
                  :per-page-changed="props.perPageChanged"
               />
            </template>
         </vue-good-table>
      </b-card-code>

      <b-modal
         id="modal-form"
         size="lg"
         scrollable
         no-close-on-backdrop
         title="Form Pengaduan"
      >
         <div style="height: 70vh;">
            <validation-observer ref="myForm">
               <b-form>
                  <b-form-group label="Nomor" label-for="nomor">
                     <validation-provider #default="{ errors }" name="Nomor" rules="required">
                        <b-form-input
                           v-model="form.nomor"
                           :state="errors.length > 0 ? false : true"
                           type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                  </b-form-group>
                  <b-form-group label="Perihal" label-for="perihal">
                     <validation-provider #default="{ errors }" name="Perihal" rules="required">
                        <b-form-input
                           v-model="form.hal"
                           :state="errors.length > 0 ? false : true"
                           type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                  </b-form-group>
                  <b-form-group label="Keterangan" label-for="keterangan">
                     <validation-provider #default="{ errors }" name="Keterangan" rules>
                        <b-form-textarea
                           id="keterangan"
                           v-model="form.keterangan"
                           rows="3"
                           max-rows="6"
                           :state="errors.length > 0 ? false : true"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                  </b-form-group>

                  <b-row>
                     <b-col md="6">
                        <b-form-group label="Tanggal Pengaduan" label-for="tgl_pengaduan">
                           <validation-provider
                              #default="{ errors }"
                              name="Tanggal Pengaduan"
                              rules="required"
                           >
                              <b-form-datepicker
                                 id="tgl_pengaduan"
                                 v-model="form.tgl_pengaduan"
                                 class="mb-1"
                                 :state="errors.length > 0 ? false : true"
                                 locale="id"
                                 :hide-header="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                           </validation-provider>
                        </b-form-group>
                     </b-col>
                     <b-col md="6">
                        <b-form-group label="Tanggal Selesai" label-for="tgl_selesai">
                           <validation-provider #default="{ errors }" name="Tanggal Selesai" rules>
                              <b-form-datepicker
                                 id="tgl_selesai"
                                 v-model="form.tgl_selesai"
                                 class="mb-1"
                                 :state="errors.length > 0 ? false : true"
                                 locale="id"
                                 :hide-header="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                           </validation-provider>
                        </b-form-group>
                     </b-col>
                  </b-row>

                  <b-form-group label="Status" label-for="status">
                     <validation-provider #default="{ errors }" name="Status " rules="required">
                        <b-form-select v-model="form.status" :options="optionsStatus" :state="errors.length > 0 ? false : true" />
                        <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                  </b-form-group>
                  <hr>
                  <b-form-group label="Nama Pelapor" label-for="nama">
                     <validation-provider #default="{ errors }" name="Nama" rules="required">
                        <b-form-input
                           v-model="form.nama"
                           :state="errors.length > 0 ? false : true"
                           type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                  </b-form-group>
                  <b-form-group label="No. Hp Pelapor" label-for="nohp">
                     <validation-provider #default="{ errors }" name="No. Hp" rules>
                        <b-form-input
                           v-model="form.nohp"
                           :state="errors.length > 0 ? false : true"
                           type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                  </b-form-group>
                  <b-form-group label="Alamat Pelapor" label-for="alamat">
                     <validation-provider #default="{ errors }" name="Alamat" rules>
                        <b-form-input
                           v-model="form.alamat"
                           :state="errors.length > 0 ? false : true"
                           type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                  </b-form-group>
               </b-form>
            </validation-observer>
         </div>
         <template #modal-footer>
            <div class="w-100">
               <b-button
                  v-b-tooltip.hover.top="'Simpan'"
                  variant="primary"
                  type="button"
                  class="float-right"
                  @click.prevent="onSave"
               >
                  <b-spinner v-if="isLoading" small /> Simpan
               </b-button>
            </div>
         </template>
      </b-modal>

      <b-modal
         id="modal-detail"
         scrollable
         size="lg"
         hide-footer
         title="Detail"
      >
         <b-list-group>
            <b-list-group-item>
               <b>Nomor</b>
               : {{ item.nomor }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Perihal</b>
               : {{ item.hal }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Keterangan</b>
               : {{ item.keterangan }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Tanggal Pengaduan</b>
               : {{ item.tgl_pengaduan | moment("DD MMMM YYYY") }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Tanggal Selesai</b>
               : {{ item.tgl_selesai | moment("DD MMMM YYYY") }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Status</b>
               : <b-badge :variant="statusVariant(item.status)" class="text-white">
                  <small>{{ statusPengaduan(item.status) }}</small>
               </b-badge>
            </b-list-group-item>
            <b-list-group-item>
               <b>Nama Pelapor</b>
               : {{ item.nama }}
            </b-list-group-item>
            <b-list-group-item>
               <b>No.hp</b>
               : {{ item.nohp }}
            </b-list-group-item>
            <b-list-group-item>
               <b>Alamat</b>
               : {{ item.alamat }}
            </b-list-group-item>
         </b-list-group>
      </b-modal>
   </div>
</template>

<style scoped>
table > tbody > tr > td > span > hr {
   margin-top: 0rem;
   margin-bottom: 0rem;
}

.nowrap {
   white-space: nowrap;
}
</style>
